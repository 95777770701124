@media (hover: hover) {
  .resizer {
    opacity: 0.9;
  }

  *:hover > .resizer {
    opacity: 1;
    width: 4px;
  }
}

.resizer {
  position: relative;
  width: 10px; /* Adjust the width as needed */
  height: 100%; /* Adjust the height as needed */
}

.resizer::before,
.resizer::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px; /* The width of the transparent padding */
  background: transparent;
  z-index: -1; /* Make sure it doesn’t cover the resizer itself */
}

.resizer::before {
  left: -6px; /* Position the transparent padding on the left */
}
