.submit-btn {
  -webkit-font-smoothing: antialiased;
  appearance: none;
  backface-visibility: hidden;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family:
    Inter,
    -apple-system,
    system-ui,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
  margin-inline: 1rem;
}

.submit-btn:not(:disabled):hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(-1px);
  transition-duration: 0.35s;
  box-shadow: rgba(39, 174, 96, 0.2) 0 6px 12px;
}

.submit-btn:active {
  transform: translateY(-1px);
  transition-duration: 0.35s;
}

.submit-btn:disabled {
  background: grey;
  cursor: not-allowed;
}

#key-list-items {
  list-style-type: none;
  font-size: 1.4rem;
}

#key-list-items li:nth-child(1)::before,
#key-list-items li:nth-child(2)::before,
#key-list-items li:nth-child(3)::before {
  content: '';
  display: inline-block;
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  background-color: black; /* Adjust the color as needed */
  margin-right: 5px; /* Space between the square and the list item text */
}

#key-list-items li:nth-child(1)::before {
  background-color: var(--pastle-blue-light);
}

#key-list-items li:nth-child(2)::before {
  background-color: var(--pastle-green);
}

#key-list-items li:nth-child(3)::before {
  background-color: var(--pastle-red);
}

.general-loader {
  font-family: Arial, Helvetica, sans-serif;
  color: #ccc;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: 5px solid #fff;
  border-bottom-color: var(--mainBlue);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
