.test_lol::-webkit-scrollbar {
  width: 0.8rem;
  /* display: none; */
}

.test_lol::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 4px;
  background-color: #274c77 !important;
  opacity: 0.5;
}

.test_lol::-webkit-scrollbar-track {
  opacity: 0 !important;
}
