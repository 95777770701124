.topLine,
.bottomLine {
  fill: none;
  stroke: #274c77;
  stroke-width: 30px;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: animateTop 2s forwards ease-in-out infinite;
}
.bottomLine {
  animation: animateBottom 2s forwards ease-in-out infinite;
}
.circle-segment {
  fill: none;
  transform-origin: center;
  animation: animateCircle 1s linear infinite;

  stroke: url(#grad);

  stroke-width: 2px;
}

.topLine,
.bottomLine {
  fill: none;
  stroke: #274c77;
  stroke-width: 30px;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: animateTop 2s forwards ease-in-out infinite;
}
.bottomLine {
  animation: animateBottom 2s forwards ease-in-out infinite;
}
.circle-segment {
  fill: none;
  transform-origin: center;
  animation: animateCircle 1s linear infinite;

  stroke: url(#grad);

  stroke-width: 2px;
}

svg circle {
  fill: none;
  /*   stroke: blue; */
  opacity: 0.3;
  /*   stroke-dasharray: 20; */
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animateBottom {
  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes animateTop {
  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  50% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

svg circle {
  fill: none;
  /*   stroke: blue; */
  opacity: 0.3;
  /*   stroke-dasharray: 20; */
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animateBottom {
  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes animateTop {
  0% {
    opacity: 0;
    stroke-dashoffset: 200;
  }

  50% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}
