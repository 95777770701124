.CalendarHeatMap__tooltip {
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 5px 10px;
  font-family:
    'Segoe UI',
    -apple-system,
    BlinkMacSystemFont,
    'Roboto',
    'Helvetica Neue',
    sans-serif;
  font-size: 12px;
  text-align: left;
}

.CalendarHeatMap__tooltipProjects {
  margin-top: 10px;
}

.CalendarHeatMap__tooltipLabel {
  word-break: break-all;
  white-space: normal;
}

.CalendarHeatMap__tooltipValue {
  font-weight: bold;
  flex-shrink: 0;
  align-self: flex-end;
}
