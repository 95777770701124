html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 300;
  font-family: 'Jost';
}

body {
  min-width: 1000px;
}

h2 {
  margin-top: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #274c77;
  /* -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.1) inset; */
  transition: background-color 5000s ease-in-out 0s;
}

/* Custom horizontal scrollbar for navbar */
.main-navbar::-webkit-scrollbar {
  height: 8px;
}

.main-navbar::-webkit-scrollbar-track {
  background: transparent;
}

.main-navbar::-webkit-scrollbar-thumb {
  background-color: rgba(39, 76, 119, 0.5);
  border-radius: 10px;
  border: 2px solid #f0f6ff;
}

.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.noscrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

:root {
  --pastle-green-light: #c1f1dd;
  --pastle-green: #98e3c3;
  --pastle-green-darker: #57a181;
  --pastle-blue-light: #d2e8ff;
  --pastle-blue-lighter: #f0f6ff;
  --pastle-blue: #99c3ed;
  --pastle-blue-darker: #608eb6;
  --pastle-red-light: #fbc5c7;
  --pastle-red: #f69b9f;
  --pastle-red-darker: #924045;
  --mainBlue: #274c77;
}

.MuiTableSortLabel-icon {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}
